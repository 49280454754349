import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import type { ReactNode } from "react";
import { useModal } from "../../contexts/modal-provider";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { cn } from "@/lib/utils";
type Props = {
	subheading?: string;
	children: ReactNode;
	defaultOpen?: boolean;
	type?: "modal" | "sheet"
	className?: string
};

const CustomModal = ({ children, subheading, type, className }: Props) => {
	const { setClose, isOpen } = useModal();
	const handleClose = () => setClose();
	if(type === "sheet"){
	return (
		<Sheet open={isOpen} onOpenChange={handleClose}>
			<SheetContent>
				<SheetHeader>
					<SheetTitle>{subheading}</SheetTitle>
				</SheetHeader>
				<div className={cn(["px-2 py-2 max-md:p-3 h-full", className])}>
					{children}
				</div>
			</SheetContent>
		</Sheet>
		);
	}
	return (
		<Dialog open={isOpen} onOpenChange={handleClose}>
			<DialogContent className={cn(["px-4 py-2 max-md:p-3 max-w-[600px]", className])}>
				<DialogHeader>
					<DialogTitle className="hidden">Modal</DialogTitle>
					<DialogDescription className="text-center">
						{subheading}
					</DialogDescription>
				</DialogHeader>
				{children}
			</DialogContent>
		</Dialog>
	);
};

export default CustomModal;